import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import TransactionSubscription from '../../../graphql/subscriptions/transaction';
import walletUtils from '../../../../utils/wallet.utils';
import { Button } from '@/components/button';
import { useAppsyncGetDepoWdTransaction } from '@/hooks/useAppsyncHook.hook';

const RealtimeTransactionComponent = () => {
	const { t } = useTranslation();
	// const [transactionType, setTransactionType] = useState('deposit');
	const [depoWdData, setDepoWdData] = useState([]);
	const [transactionType, setTransactionType] = useState('deposit');

	useAppsyncGetDepoWdTransaction({ setDepoWdData: setDepoWdData });

	return (
		<>
			<div className="flex flex-row w-full bg-inherit text-sm sm:text-base gap-2 mb-section">
				<Button
					onClick={() => setTransactionType('deposit')}
					variant={transactionType === 'deposit' ? 'gradient_full' : 'gradient_border'}>
					{t('btn.lastDeposit')}
				</Button>
				<Button
					onClick={() => setTransactionType('withdraw')}
					variant={transactionType === 'withdraw' ? 'gradient_full' : 'gradient_border'}>
					{t('btn.lastWithdrawal')}
				</Button>
			</div>
			<div className="gradient-button rounded-lg mb-section text-container-color-primary">
				<div className="gradient-border-button rounded-lg">
					<div className="p-1 w-full">
						<div className="relative w-full h-80 overflow-hidden">
							<div className="flex flex-row">
								<div className="flex items-center flex-1 w-full h-10">{t('thead.user')}</div>
								<div className="flex items-center flex-1 w-full h-10">{t('thead.amount')}</div>
							</div>
							{transactionType === 'deposit' && (
								<SwiperComponent
									data={depoWdData.filter((t) => t?.transactionType === 'DEPOSIT')}
								/>
							)}
							{transactionType === 'withdraw' && (
								<SwiperComponent
									data={depoWdData.filter((t) => t?.transactionType === 'WITHDRAW')}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default RealtimeTransactionComponent;

const SwiperComponent = ({ data = [] }) => {
	return (
		<>
			{data?.length ? (
				<Swiper
					direction={'vertical'}
					slidesPerView={7}
					loop={true}
					speed={1000}
					allowTouchMove={false}
					autoplay={{
						delay: 500,
						disableOnInteraction: false
					}}
					modules={[Autoplay]}
					className="mySwiper w-full h-full">
					{data?.map((item, index) => (
						<SwiperSlide key={index}>
							<div className="h-10 gradient-button rounded-lg">
								<div className="gradient-border-button h-full rounded-lg">
									<div className="flex flex-row w-full h-full px-2">
										<div className="flex items-center flex-1 w-full">{item?.username}</div>
										<div className="flex gap-2 items-center flex-1 w-full">
											<img className="w-5 h-5" src={item?.currencyLogo} alt="currency logo" />
											<span>{walletUtils.formatCurrency(item?.amount)}</span>
										</div>
									</div>
								</div>
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			) : null}
		</>
	);
};
